import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index'
import CustomerList from '@/views/CustomerList/index'
import CustomerDetail from '@/views/CustomerDetail/index'
import StoreList from '@/views/StoreList/index'
import StoreDetail from '@/views/StoreDetail/index'
import CategoryList from '@/views/CategoryList/index'
import CategoryDetail from '@/views/CategoryDetail/index'
import ProductList from '@/views/ProductList/index'
import ProductDetail from '@/views/ProductDetail/index'
import ExtraList from '@/views/ExtraList/index'
import ExtraDetail from '@/views/ExtraDetail/index'
import ItemList from '@/views/ItemList/index'
import ItemDetail from '@/views/ItemDetail/index'
import UpdateLog from '@/views/UpdateLog/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        component: Home,
        children: [
			// 客戶頁面
			{
				path: '/customers',
				component: CustomerList,
			},
			{
				path: '/customers/:operation',
				component: CustomerDetail,
			},
			{
				path: '/customers/:operation/:id',
				component: CustomerDetail,
			},
			// 分店頁面
			{
				path: '/stores',
				component: StoreList,
			},
			{
				path: '/stores/:operation',
				component: StoreDetail,
			},
			{
				path: '/stores/:operation/:id',
				component: StoreDetail,
			},
			// 分類頁面
			{
				path: '/categories',
				component: CategoryList,
			},
			{
				path: '/categories/:operation',
				component: CategoryDetail,
			},
			{
				path: '/categories/:operation/:id',
				component: CategoryDetail,
			},
			// 商品頁面
			{
				path: '/products',
				component: ProductList,
			},
			{
				path: '/products/:operation',
				component: ProductDetail,
			},
			{
				path: '/products/:operation/:id',
				component: ProductDetail,
			},
			// 配料頁面
			{
				path: '/extras',
				component: ExtraList,
			},
			{
				path: '/extras/:operation',
				component: ExtraDetail,
			},
			{
				path: '/extras/:operation/:id',
				component: ExtraDetail,
			},
            // 配料選項頁面
			{
				path: '/items',
				component: ItemList,
			},
			{
				path: '/items/:operation',
				component: ItemDetail,
			},
			{
				path: '/items/:operation/:id',
				component: ItemDetail,
			},
			// 更新日誌頁面
			{
				path: '/updateLog',
				component: UpdateLog,	
			},
        ]
    },
	{
		path: '*',
		redirect: '/home'
	}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
