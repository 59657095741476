import NavBar from '@/components/NavBar'

export default {
    components: {
        NavBar,
    },

    data() {
        return {
            links: [
                {
                    text: '商品管理',
                    icon: 'tags',
                    children: [
                        { text: '分類', path: '/categories' },
                        { text: '商品', path: '/products' },
                        { text: '配料', path: '/extras' },
                        { text: '配料選項', path: '/items' },
                    ]
                },
                {
                    text: '商家管理',
                    icon: 'shop',
                    children: [
                        { text: '分店', path: '/stores' },
                    ]
                },
                {
                    text: '客戶管理',
                    icon: 'people',
                    children: [
                        { text: '客戶', path: '/customers' },
                    ]
                },
                {
                    text: '更新日誌',
                    icon: 'gear',
                    path: '/updateLog'
                }
            ],
        }
    }
}