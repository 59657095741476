import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

// firebase.initializeApp({
//     apiKey: 'AIzaSyAFLkeQvEpWTZbTsAJxAIXyH-ghykKgUkg',
//     authDomain: 'nube-yau-kwong-kee.firebaseapp.com',
//     projectId: 'nube-yau-kwong-kee',
//     storageBucket: 'nube-yau-kwong-kee.appspot.com',
//     messagingSenderId: '815743492670',
//     appId: '1:815743492670:web:2d9332723038cdbc3ca76f',
//     measurementId: 'G-31C15EV8PJ',
// })

firebase.initializeApp({
    apiKey: 'AIzaSyCU90X4GOlVhfhWzlmu9A6iRmvoay5FbeI',
    authDomain: 'nube-golden-chicken.firebaseapp.com',
    projectId: 'nube-golden-chicken',
    storageBucket: 'nube-golden-chicken.appspot.com',
    messagingSenderId: '257060341765',
    appId: '1:257060341765:web:859c66188933080d67f46c',
    measurementId: 'G-SB4G2X1GD1',
})

export const db = firebase.firestore()
export const functions = firebase.functions()
export const storage = firebase.storage()