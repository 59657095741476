export function Item() {
    return {
        name: '',
    }
}

export function Extra() {
    return {
        name: '',
        isMultiple: false,
        minSelected: 1,
        maxSelected: 1,
        items: []
    }
}

export function Product() {
    return {
        name: '',
        description: '',
        price: '',
        status: 1,
        extras: []
    }
}

export function Category() {
    return {
        name: '',
        status: 1,
        products: []
    }
}

export function Store() {
    return {
        name: '',
        phone: '',
        address: '',
        readyTime: 0,
        reservationDays: 0,
        closed: false,
        openHours: {
            0: [{ start: '09:00', end: '21:00' }],
            1: [{ start: '09:00', end: '21:00' }],
            2: [{ start: '09:00', end: '21:00' }],
            3: [{ start: '09:00', end: '21:00' }],
            4: [{ start: '09:00', end: '21:00' }],
            5: [{ start: '09:00', end: '21:00' }],
            6: [{ start: '09:00', end: '21:00' }],
        },
        categories: [],
    }
}

export function Customer() {
    return {
        name: '',
        phone: '',
        birthMonth: '',
        gender: '',
        point: 0,
        type: 'membership',
        discounts: []
    }
}