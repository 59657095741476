export default {
    data() {
        return {
            logs: [
                {
                    version: 'v1.0.1',
                    message: '加入更新日誌',
                    date: '21-04-05 19:28'
                },
                {
                    version: 'v1.1',
                    message: '加入圖片上傳',
                    date: '21-04-13 12:00'
                },
                {
                    version: 'v1.1.1',
                    message: '加入刪除功能',
                    date: '21-04-13 12:15'
                }
            ],
        }
    }
}