import _ from 'lodash'

export default {
    state: {
        products: [],
    },
    mutations: {},
    actions: {
        bindProducts({ dispatch }) {
            return dispatch('bindCollection', 'products')
        },

        async createProduct({ dispatch }, data) {
            const result = await dispatch('createDocument', { collection: 'products', data: data.product })
            if (!data.newImage) return result
            data.product.id = result.id
            data.product.image = await dispatch('createFile', { path: 'products/' + result.id, file: data.newImage })
            return await dispatch('updateDocument', { collection: 'products', data: data.product })
        },

        async getProduct({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'products', id: id })
        },

        getProductInShop({ state }, id) {
            if (!state.shop) return null
            let result = null
            _.forEach(state.shop.categories, category => {
                result = _.find(category.products, { id: id })
            })
            return result
        },

        async updateProduct({ state, dispatch }, data) {
            if (data.newImage) {
                data.product.image = await dispatch('createFile', { path: 'products/' + data.product.id, file: data.newImage })
            }
            _.forEach(state.categories, async category => {
                const index = _.findIndex(category.products, { id: data.product.id })
                if (index > -1) {
                    category.products[index] = data.product
                    await dispatch('updateCategory', category)
                }
            })
            return await dispatch('updateDocument', { collection: 'products', data: data.product })
        },

        async deleteProduct({ state, dispatch }, data) {
            _.forEach(state.categories, async category => {
                const index = _.findIndex(category.products, { id: data })
                if (index > -1) {
                    category.products.splice(index, 1)
                    await dispatch('updateCategory', category)
                }
            })
            return await dispatch('deleteDocument', { collection: 'products', id: data })
        }
    },
}
