import { Customer } from '@/models/index'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            customer: null,
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.customer = Customer()
                break;
            default:
                this.customer = await this.$store.dispatch('getCustomer', this.$route.params.id)
                break;
        }
        if (!this.customer) return this.$router.push('/customers')
    },

    methods: {
        async submit() {
            const result = await this.$store.dispatch(this.customer.id ? 'updateCustomer' : 'createCustomer', this.customer)
            if (result) {
                this.$root.$bvToast.toast(`${this.customer.name} - 配料選項${this.customer.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/customers')
            } else {
                this.$root.$bvToast.toast(`${this.customer.name} - 配料選項${this.customer.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        }
    }
}