<template>
    <b-table class="mt-3" :items="items" :fields="fields" hover>
        <template #cell(actions)="row">
            <b-link class="mr-3" @click="$router.push(`/${value}/view/${row.item.id}`)">查看</b-link>
            <b-link class="mr-3" @click="$router.push(`/${value}/edit/${row.item.id}`)">修改</b-link>
            <b-link style="color: #c82333;" @click="$emit('remove', row.item)">刪除</b-link>
        </template>
    </b-table>
</template>

<script>
export default {
    props: ['value', 'items', 'fields']
}
</script>