import { mapState } from 'vuex'
import { Extra } from '@/models/index'
import _ from 'lodash'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            extra: null,
            isMultipleOptions: [
                { text: '複選', value: true },
                { text: '單選', value: false },
            ]
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.extra = Extra()
                break;
            default:
                this.extra = await this.$store.dispatch('getExtra', this.$route.params.id)
                break;
        }
        if (!this.extra) return this.$router.push('/extras')
    },

    computed: {
        ...mapState({
            items: state => state.items
        }),

        itemOptions() {
            return _.map(_.cloneDeep(this.items), item => {
                return {
                    ...item,
                    price: 0,
                    isDefault: 0,
                }
            })
        }
    },

    methods: {
        addItem(obj) {
            if (!_.find(this.extra.items, { id: obj.id })) {
                this.extra.items.push(_.cloneDeep(obj))
            }
        },

        removeItem(index) {
            this.extra.items.splice(index, 1)
        },

        async submit() {
            try {
                await this.$store.dispatch(this.extra.id ? 'updateExtra' : 'createExtra', this.extra)
                this.$root.$bvToast.toast(`${this.extra.name} - 配料${this.extra.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/extras')
            } catch(e) {
                console.log(e)
                this.$root.$bvToast.toast(`${this.extra.name} - 配料${this.extra.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        }
    }
}