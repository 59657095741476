import { mapState } from 'vuex'
import { Store } from '@/models/index'
import _ from 'lodash'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            store: null,
            weekdayLabels: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.store = Store()
                break;
            default:
                this.store = await this.$store.dispatch('getStore', this.$route.params.id)
                break;
        }
        if (!this.store) return this.$router.push('/stores')
    },

    computed: {
        ...mapState({
            categories: state => state.categories,
        })
    },

    methods: {
        addCategory(obj) {
            if (!_.find(this.store.categories, { id: obj.id })) {
                this.store.categories.push(_.cloneDeep(obj))
            }
        },

        removeCategory(index) {
            this.store.categories.splice(index, 1)
        },
        
        async submit() {
            try {
                await this.$store.dispatch(this.store.id ? 'updateStore' : 'createStore', this.store)
                this.$root.$bvToast.toast(`${this.store.name} - 分店${this.store.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/stores')
            } catch(e) {
                console.log(e)
                this.$root.$bvToast.toast(`${this.store.name} - 分店${this.store.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        }
    }
}