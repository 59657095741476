import ListHeader from '@/components/ListHeader'
import ListTable from '@/components/ListTable'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
    components: {
        ListHeader,
        ListTable,
    },
    
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '配料',
                },
                {
                    key: 'isMultiple',
                    label: '類型',
                    formatter: value => {
                        return value > 0 ? '複選' : '單選'
                    },
                },
                {
                    key: 'items',
                    label: '選項',
                    formatter: value => {
                        let str = ''
                        _.forEach(value, (data, index) => {
                            if (index === value.length - 1) {
                                str += data.name
                            } else {
                                str += data.name + ', '
                            }
                        })
                        return str ? str : '-'
                    },
                },                
                {
                    key: 'actions',
                    label: '功能',
                    thStyle: { width: '25%' },
                }
            ]
        }
    },

    computed: {
        ...mapState({
            extras: state => state.extras
        })
    },

    methods: {
        removeExtra({ id }) {
            this.$store.dispatch('deleteExtra', id)
        }
    }
}