import { functions } from '@/firebase'

export default {
    state: {
        users: [],
    },
    mutations: {},
    actions: {
        bindCustomers({ dispatch }) {
            return dispatch('bindCollection', 'users')
        },

        async createCustomer(store, data) {
            const createCustomer = functions.httpsCallable('createCustomer')
            return await createCustomer(data).then(result => {
                return result.data.status
            })
        },

        async getCustomer({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'users', id: id })
        },

        async updateCustomer({ dispatch }, data) {
            return await dispatch('updateDocument', { collection: 'users', data: data })
        },
    },
}
