import ListHeader from '@/components/ListHeader'
import ListTable from '@/components/ListTable'
import { mapState } from 'vuex'

export default {
    components: {
        ListHeader,
        ListTable,
    },
    
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '分店',
                },
                {
                    key: 'address',
                    label: '地址',
                },
                {
                    key: 'phone',
                    label: '電話',
                },
                {
                    key: 'actions',
                    label: '功能',
                    thStyle: { width: '25%' },
                }
            ]
        }
    },

    computed: {
        ...mapState({
            stores: state => state.stores,
        })
    }
}