<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch('bindCustomers')
        this.$store.dispatch('bindStores')
        this.$store.dispatch('bindCategories')
        this.$store.dispatch('bindProducts')
        this.$store.dispatch('bindExtras')
        this.$store.dispatch('bindItems')
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
